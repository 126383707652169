.container,
.spacer {
	height: 50px;
}

.container {
	position: fixed;
	background: white;
	width: 100%;
	z-index: 1;
	box-shadow: 0px 1px 5px 1px #0000000f;

	display: flex;
}

.container h1 {
	margin: 0;
}

.container h1 img {
	padding-left: 10px;
	padding-top: 10px;
	height: 30px;
}

.container > div {
	font-size: 8pt;
	margin-left: auto;
	margin-right: 20px;
	align-self: center;
}

.container > div p {
	margin: 0;
	text-align: right;
	padding: 2px 0;
}
.container > div p b {
	padding-left: 10px;
}
