.table {
	width: 100%;
	border-collapse: collapse;
}

.table th {
	text-align: left;
}

.table tr {
	border-bottom: 1px solid rgba(128, 128, 128, 0.336);
}
.table tr td:first-child {
	text-align: center;
	font-weight: 700;
}

.table tr.quantityRow {
	border: none;
}
.table tr.quantityRow td {
	padding: 12px 0;
	padding-bottom: 0;
}
.table tr.quantityRow td:first-child {
	padding-right: 8px;
	text-align: right;
	font-weight: 700;
}

.table tr:last-child td {
	padding-top: 0;
}

.table td {
	padding: 0;
	padding-right: 10px;
}

.cell.smallCell {
	width: 50px;
}

.cell input {
	box-sizing: border-box;
	width: 100%;
	border: 2px solid transparent;
	outline: none;
	background-color: transparent;
	padding: 6px 0;

	transition: border 0.15s ease;
	font-size: 10pt;
	border-radius: 4px;
}

.cell input:focus {
	border-color: #9ed6e1;
}
