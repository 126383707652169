.container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	color: white;

	background-position: center;
	background-size: cover;
	background-image: url(../img/background.jpg);
	background-color: black;
}

.container div {
	top: 30vh;
	margin-left: 20px;
	font-size: 1.18vw;
	position: relative;
	text-align: left;
}

@media (min-width: 1200px) {
	.container div {
		font-size: 14px;
	}
}

@media (max-width: 500px) {
	.container div {
		font-size: 6px;
	}
}

.container img {
	height: 1.8em;
}

.container h2,
.container h3 {
	font-size: 1.2em;
	font-weight: 400;
	margin: 0;
}

.container h3 {
	font-size: 1em;
	margin-top: 0.5em;
}

.button {
	margin-top: 2em !important;
	font-size: 1.2em;
	left: 20%;
	font-size: 16px;
	position: relative;
}
