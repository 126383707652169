.inlineFields {
	display: flex;
}

.inlineFields > div {
	padding: 0 8px;
	flex: 100% 0 0;
	box-sizing: border-box;
}
.inlineFields > div:first-child {
	padding-left: 0;
}
.inlineFields > div:last-child {
	flex-grow: 1;
	padding-right: 0;
}

.fieldWrapper {
	margin-top: 16px;
}

.fieldWrapper label {
	display: block;
	font-size: 10pt;
	margin-top: 0;
	margin-bottom: 4px;
	font-weight: 700;
}

.textInput {
	padding: 10px;
	border: 2px solid #d9eef6;
	outline: none;
	appearance: none;
	border-radius: 6px;
	background: white;
	display: block;
	transition: border 0.2s ease;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
.focused .textInput {
	border-color: #9ed6e1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075);
}

.selectWrapper {
	position: relative;
}
.selectWrapper select {
	width: 100%;
}

.selectWrapper::after {
	content: "\f0d7";
	font-family: "FontAwesome";
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	line-height: 42px;
	pointer-events: none;
	color: #9ed6e1;
}

.button {
	display: block;

	background-color: white;
	padding: 0.3em 0.8em;
	border-radius: 6px;
	font-size: 10pt;
	border: 1px solid hsla(0, 0%, 72.9%, 0.6);
	font-weight: 600;

	transition: box-shadow 0.12s ease;
	cursor: pointer;
	outline: none;

	box-shadow: 0 2px 5px 0 rgba(60, 66, 87, 0.12);
}

.button:hover {
	box-shadow: 0 3px 8px 0 rgba(60, 66, 87, 0.18);
}

/* Small button adjustments */
.button.btnSmall {
	font-size: 8pt;
}

.button.centre {
	margin: 0 auto;
	margin-top: 16px;
}

.Panel {
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #d7dbdb;
	border-radius: 6px;
	padding: 16px;
}

.Panel.panelMargin {
	margin: 36px auto;
}

.Panel h3 {
	font-size: 16pt;
	margin: 0;
	font-weight: 400;
	color: rgb(60, 60, 60);
}

.Panel > p {
	margin: 0;
}

.Panel > hr {
	border: none;
	border-bottom: 1px solid #eee;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 20px;
}

.allergens {
	display: grid;

	grid-template-columns: repeat(auto-fill, 110px);
	grid-gap: 1em;
	justify-content: center;
}

.allergens label {
	cursor: pointer;
}

.allergens img {
	width: 100%;
	opacity: 0.3;
	transition: 0.2s opacity ease;
}

.allergens input {
	display: none;
}

.allergens input:checked + img {
	opacity: 1;
}
