.container hr {
	margin-top: 4px;
	margin-bottom: 8px;
	border: 1px solid #eee;
	border-top: none;
}

.headerRow {
	display: flex;
}
.headerRow button {
	display: block;
	border-radius: 4px;
	background: none;
	border: none;
	outline: none;
	font-size: 15px;
	cursor: pointer;
}

.headerRow button:hover {
	background: #eee;
}
.headerRow button.active {
	background: white;
	color: rgb(26, 115, 232);
}

:global(.public-DraftEditorPlaceholder-inner) {
	color: #9197a3;
	position: absolute;
	z-index: 0;
	pointer-events: none;
}

.headerRow button:disabled {
	background: #e6e6e6;
}
