@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,300,700|Open+Sans+Condensed:300,700|Roboto+Slab:400,100,300,700);
.Navbar_container__1RifL,
.Navbar_spacer__GcmUm {
	height: 50px;
}

.Navbar_container__1RifL {
	position: fixed;
	background: white;
	width: 100%;
	z-index: 1;
	box-shadow: 0px 1px 5px 1px #0000000f;

	display: flex;
}

.Navbar_container__1RifL h1 {
	margin: 0;
}

.Navbar_container__1RifL h1 img {
	padding-left: 10px;
	padding-top: 10px;
	height: 30px;
}

.Navbar_container__1RifL > div {
	font-size: 8pt;
	margin-left: auto;
	margin-right: 20px;
	align-self: center;
}

.Navbar_container__1RifL > div p {
	margin: 0;
	text-align: right;
	padding: 2px 0;
}
.Navbar_container__1RifL > div p b {
	padding-left: 10px;
}

.LivePreview_container__Pb2a7 {
	background: #eee;
	flex-grow: 1;
	position: fixed;
	width: 400px;
	right: 0;
	top: 50px;
	bottom: 0;

	/* override */
	font-family: serif;
	font-family: initial;

	border-left: 1px solid #d7dbdb;
}

.LivePreview_container__Pb2a7 h3 {
	font-weight: 300;
	text-align: center;
}

.LivePreview_container__Pb2a7 svg {
	max-height: 60%;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	margin: auto;
	display: block;
	padding: 20px;
}

.FormComponents_inlineFields__1oRi7 {
	display: flex;
}

.FormComponents_inlineFields__1oRi7 > div {
	padding: 0 8px;
	flex: 100% 0;
	box-sizing: border-box;
}
.FormComponents_inlineFields__1oRi7 > div:first-child {
	padding-left: 0;
}
.FormComponents_inlineFields__1oRi7 > div:last-child {
	flex-grow: 1;
	padding-right: 0;
}

.FormComponents_fieldWrapper__1G4ru {
	margin-top: 16px;
}

.FormComponents_fieldWrapper__1G4ru label {
	display: block;
	font-size: 10pt;
	margin-top: 0;
	margin-bottom: 4px;
	font-weight: 700;
}

.FormComponents_textInput__3bZgi {
	padding: 10px;
	border: 2px solid #d9eef6;
	outline: none;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	border-radius: 6px;
	background: white;
	display: block;
	-webkit-transition: border 0.2s ease;
	transition: border 0.2s ease;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
.FormComponents_focused__RMeyi .FormComponents_textInput__3bZgi {
	border-color: #9ed6e1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075);
}

.FormComponents_selectWrapper__3loSF {
	position: relative;
}
.FormComponents_selectWrapper__3loSF select {
	width: 100%;
}

.FormComponents_selectWrapper__3loSF::after {
	content: "\f0d7";
	font-family: "FontAwesome";
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	line-height: 42px;
	pointer-events: none;
	color: #9ed6e1;
}

.FormComponents_button__11T-z {
	display: block;

	background-color: white;
	padding: 0.3em 0.8em;
	border-radius: 6px;
	font-size: 10pt;
	border: 1px solid hsla(0, 0%, 72.9%, 0.6);
	font-weight: 600;

	-webkit-transition: box-shadow 0.12s ease;

	transition: box-shadow 0.12s ease;
	cursor: pointer;
	outline: none;

	box-shadow: 0 2px 5px 0 rgba(60, 66, 87, 0.12);
}

.FormComponents_button__11T-z:hover {
	box-shadow: 0 3px 8px 0 rgba(60, 66, 87, 0.18);
}

/* Small button adjustments */
.FormComponents_button__11T-z.FormComponents_btnSmall__2OhO2 {
	font-size: 8pt;
}

.FormComponents_button__11T-z.FormComponents_centre__2JXTI {
	margin: 0 auto;
	margin-top: 16px;
}

.FormComponents_Panel__1xPwq {
	box-sizing: border-box;
	background: #fff;
	border: 1px solid #d7dbdb;
	border-radius: 6px;
	padding: 16px;
}

.FormComponents_Panel__1xPwq.FormComponents_panelMargin__rockl {
	margin: 36px auto;
}

.FormComponents_Panel__1xPwq h3 {
	font-size: 16pt;
	margin: 0;
	font-weight: 400;
	color: rgb(60, 60, 60);
}

.FormComponents_Panel__1xPwq > p {
	margin: 0;
}

.FormComponents_Panel__1xPwq > hr {
	border: none;
	border-bottom: 1px solid #eee;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 20px;
}

.FormComponents_allergens__1pI3m {
	display: grid;

	grid-template-columns: repeat(auto-fill, 110px);
	grid-gap: 1em;
	justify-content: center;
}

.FormComponents_allergens__1pI3m label {
	cursor: pointer;
}

.FormComponents_allergens__1pI3m img {
	width: 100%;
	opacity: 0.3;
	-webkit-transition: 0.2s opacity ease;
	transition: 0.2s opacity ease;
}

.FormComponents_allergens__1pI3m input {
	display: none;
}

.FormComponents_allergens__1pI3m input:checked + img {
	opacity: 1;
}

.MarkdownEditor_container__1o2gk hr {
	margin-top: 4px;
	margin-bottom: 8px;
	border: 1px solid #eee;
	border-top: none;
}

.MarkdownEditor_headerRow__1tarF {
	display: flex;
}
.MarkdownEditor_headerRow__1tarF button {
	display: block;
	border-radius: 4px;
	background: none;
	border: none;
	outline: none;
	font-size: 15px;
	cursor: pointer;
}

.MarkdownEditor_headerRow__1tarF button:hover {
	background: #eee;
}
.MarkdownEditor_headerRow__1tarF button.MarkdownEditor_active__2K7EH {
	background: white;
	color: rgb(26, 115, 232);
}

.public-DraftEditorPlaceholder-inner {
	color: #9197a3;
	position: absolute;
	z-index: 0;
	pointer-events: none;
}

.MarkdownEditor_headerRow__1tarF button:disabled {
	background: #e6e6e6;
}

.Products_table__1KBCc {
	width: 100%;
	border-collapse: collapse;
}

.Products_table__1KBCc th {
	text-align: left;
}

.Products_table__1KBCc tr {
	border-bottom: 1px solid rgba(128, 128, 128, 0.336);
}
.Products_table__1KBCc tr td:first-child {
	text-align: center;
	font-weight: 700;
}

.Products_table__1KBCc tr.Products_quantityRow__13j9r {
	border: none;
}
.Products_table__1KBCc tr.Products_quantityRow__13j9r td {
	padding: 12px 0;
	padding-bottom: 0;
}
.Products_table__1KBCc tr.Products_quantityRow__13j9r td:first-child {
	padding-right: 8px;
	text-align: right;
	font-weight: 700;
}

.Products_table__1KBCc tr:last-child td {
	padding-top: 0;
}

.Products_table__1KBCc td {
	padding: 0;
	padding-right: 10px;
}

.Products_cell__1kQap.Products_smallCell__aI2jc {
	width: 50px;
}

.Products_cell__1kQap input {
	box-sizing: border-box;
	width: 100%;
	border: 2px solid transparent;
	outline: none;
	background-color: transparent;
	padding: 6px 0;

	-webkit-transition: border 0.15s ease;

	transition: border 0.15s ease;
	font-size: 10pt;
	border-radius: 4px;
}

.Products_cell__1kQap input:focus {
	border-color: #9ed6e1;
}

body {
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.4;
	color: #222222;
	background-color: #ffffff;
}

.DirectForm_container__1Jioq {
	padding: 16px;
	width: calc(100vw - 400px);
	box-sizing: border-box;
}

.LLLPlug_container__2dIIF {
	color: #01aec5;
	background-color: #d9eef69e;
	border-color: #01aec5;

	font-size: 11pt;

	border-left-style: solid;
	border-width: 6px;
	padding: 16px;

	display: flex;
	align-items: center;
}

.LLLPlug_container__2dIIF img {
	height: 40px;
}

.LLLPlug_text__3-y4D {
	margin: 0 12px;
	flex-grow: 1;
}

.LLLPlug_text__3-y4D p {
	margin: 0;
}

.LLLPlug_container__2dIIF a {
	text-decoration: none;
	flex-shrink: 0;
}

.LLLPlug_container__2dIIF a button {
	color: #01aec5;
	padding: 6px 16px;
}

.Modal_overlay__uPdel {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.6) !important;
	-webkit-animation: Modal_fadein__3uLvG 0.45s;
	        animation: Modal_fadein__3uLvG 0.45s;
}

.Modal_container__39_NS {
	position: relative;
	margin: 30px auto;
	overflow: auto;
	outline: none;
	z-index: 10;

	-webkit-animation-duration: 0.45s;

	        animation-duration: 0.45s;
	-webkit-animation-name: Modal_popup__2zXKG;
	        animation-name: Modal_popup__2zXKG;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-timing-function: cubic-bezier(0.54, 0.58, 0.22, 1.7);
	        animation-timing-function: cubic-bezier(0.54, 0.58, 0.22, 1.7);
	max-width: 800px;
}

@-webkit-keyframes Modal_popup__2zXKG {
	0% {
		top: 5%;
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		opacity: 0;
	}
	100% {
		top: 0%;
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;
	}
}

@keyframes Modal_popup__2zXKG {
	0% {
		top: 5%;
		-webkit-transform: scale(0.95);
		        transform: scale(0.95);
		opacity: 0;
	}
	100% {
		top: 0%;
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 1;
	}
}

body {
	margin: 0;
	padding: 0;
}

input {
	padding: 4px;
}

.App_container__6j1zw {
	display: flex;
}

.StartScreen_container__3mpEK {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	color: white;

	background-position: center;
	background-size: cover;
	background-image: url(/static/media/background.699672ae.jpg);
	background-color: black;
}

.StartScreen_container__3mpEK div {
	top: 30vh;
	margin-left: 20px;
	font-size: 1.18vw;
	position: relative;
	text-align: left;
}

@media (min-width: 1200px) {
	.StartScreen_container__3mpEK div {
		font-size: 14px;
	}
}

@media (max-width: 500px) {
	.StartScreen_container__3mpEK div {
		font-size: 6px;
	}
}

.StartScreen_container__3mpEK img {
	height: 1.8em;
}

.StartScreen_container__3mpEK h2,
.StartScreen_container__3mpEK h3 {
	font-size: 1.2em;
	font-weight: 400;
	margin: 0;
}

.StartScreen_container__3mpEK h3 {
	font-size: 1em;
	margin-top: 0.5em;
}

.StartScreen_button__226vp {
	margin-top: 2em !important;
	font-size: 1.2em;
	left: 20%;
	font-size: 16px;
	position: relative;
}

