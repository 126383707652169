@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,300,700|Open+Sans+Condensed:300,700|Roboto+Slab:400,100,300,700");

:global(body) {
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.4;
	color: #222222;
	background-color: #ffffff;
}

.container {
	padding: 16px;
	width: calc(100vw - 400px);
	box-sizing: border-box;
}
