:global(body) {
	margin: 0;
	padding: 0;
}

:global(input) {
	padding: 4px;
}

.container {
	display: flex;
}
