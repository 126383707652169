.container {
	background: #eee;
	flex-grow: 1;
	position: fixed;
	width: 400px;
	right: 0;
	top: 50px;
	bottom: 0;

	/* override */
	font-family: initial;

	border-left: 1px solid #d7dbdb;
}

.container h3 {
	font-weight: 300;
	text-align: center;
}

.container svg {
	max-height: 60%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin: auto;
	display: block;
	padding: 20px;
}
