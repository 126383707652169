.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.6) !important;
	animation: fadein 0.45s;
}

.container {
	position: relative;
	margin: 30px auto;
	overflow: auto;
	outline: none;
	z-index: 10;

	animation-duration: 0.45s;
	animation-name: popup;
	animation-delay: 0s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.54, 0.58, 0.22, 1.7);
	max-width: 800px;
}

@keyframes popup {
	0% {
		top: 5%;
		transform: scale(0.95);
		opacity: 0;
	}
	100% {
		top: 0%;
		transform: scale(1);
		opacity: 1;
	}
}
