.container {
	color: #01aec5;
	background-color: #d9eef69e;
	border-color: #01aec5;

	font-size: 11pt;

	border-left-style: solid;
	border-width: 6px;
	padding: 16px;

	display: flex;
	align-items: center;
}

.container img {
	height: 40px;
}

.text {
	margin: 0 12px;
	flex-grow: 1;
}

.text p {
	margin: 0;
}

.container a {
	text-decoration: none;
	flex-shrink: 0;
}

.container a button {
	color: #01aec5;
	padding: 6px 16px;
}
